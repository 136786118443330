const initSvgPathAnimation = () => {
  const wrapEls = document.querySelectorAll('[data-path-animation]');

  if (!wrapEls.length) {
    return;
  }

  wrapEls.forEach((wrapEl) => {
    const pathEls = wrapEl.querySelectorAll('.path-anim');
    const duration = wrapEl.hasAttribute('data-path-animation-duration') ? Number(wrapEl.getAttribute('data-path-animation-duration')) : 2000;

    pathEls.forEach((pathEl) => {
      const offset = window.anime.setDashoffset(pathEl);

      pathEl.setAttribute('stroke-dashoffset', offset * 100);
    });

    const animation = window.anime({
      targets: pathEls,
      strokeDashoffset: [window.anime.setDashoffset, 0],
      easing: 'easeInOutSine',
      duration,
      loop: 1,
      direction: 'normal',
      autoplay: false
    });

    wrapEl.addEventListener('sal:in', () => {
      animation.play();
    });
  });

};

export {initSvgPathAnimation};
