const initPageHeader = () => {
  const pageHeaderEl = document.querySelector('.header');
  const aboutEl = document.querySelector('.about');

  if (!pageHeaderEl) {
    return;
  }

  let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;

  window.addEventListener('scroll', () => {
    const st = window.pageYOffset || document.documentElement.scrollTop;

    if (st < lastScrollTop) {
      pageHeaderEl.classList.remove('header--hidden');
    } else {
      pageHeaderEl.classList.add('header--hidden');
    }

    lastScrollTop = st <= 0 ? 0 : st;
  });
};

export {initPageHeader};
