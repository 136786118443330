// import {ieFix} from './utils/ie-fix';
// import {polyfillObjectFit} from './utils/polyfill-object-fit';

import smoothscroll from 'smoothscroll-polyfill';

import {iosVhFix} from './utils/ios-vh-fix';

import {initModals} from './modules/modals/init-modals';
import {initPublications} from './modules/publications';
import {initFieldText} from './modules/field-text';
import {initTextSplitAnimation} from './modules/text-split-animation';
import {initIntro} from './modules/intro';
import {initSvgPathAnimation} from './modules/svg-path-animation';
import {initFormValidate} from './modules/form-validate';
import {initPhoneMask} from './modules/phone-mask';
import {initPageHeader} from './modules/page-header';

// ---------------------------------

history.scrollRestoration = 'manual';

window.addEventListener('DOMContentLoaded', () => {

  // Utils
  // ---------------------------------

  // только если ie11
  // ieFix();
  // https://github.com/fregante/object-fit-images
  // polyfillObjectFit();

  smoothscroll.polyfill();

  iosVhFix();

  window.sal({
    once: false,
    threshold: 0.2,
  });

  // Modules
  // ---------------------------------

  initPublications();
  initFieldText();
  initIntro();
  initSvgPathAnimation();
  initFormValidate();
  initPhoneMask();
  initPageHeader();

  // все скрипты должны быть в обработчике 'DOMContentLoaded', но не все в 'load'
  // в load следует добавить скрипты, не участвующие в работе первого экрана
  window.addEventListener('load', () => {
    initModals();
    initTextSplitAnimation();
  });
});

// ---------------------------------

// используйте .closest(el)

// для адаптивного JS используейтся matchMedia и addListener
// const breakpoint = window.matchMedia(`(min-width:1024px)`);
// const breakpointChecker = () => {
//   if (breakpoint.matches) {
//   } else {
//   }
// };
// breakpoint.addListener(breakpointChecker);
// breakpointChecker();

// привязывайте js не на бэм, а на data-cookie

// выносим все в data-attr - url до пинов карты, настройки автопрокрутки, url к json и т.д.
