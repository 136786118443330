const initIntro = () => {
  const introBtnEl = document.querySelector('.intro__btn');
  const aboutEl = document.querySelector('.about');
  let timer;

  introBtnEl.addEventListener('click', (e) => {
    e.preventDefault();

    aboutEl.scrollIntoView({
      behavior: 'smooth',
    });
  });

  let needScroll = true;
  clearTimeout(timer);

  const introLottie = document.querySelector('lottie-player');
  introLottie.addEventListener('complete', () => {
    if (needScroll) {
      timer = setTimeout(() => {
        aboutEl.scrollIntoView({
          behavior: 'smooth',
        });
        needScroll = false;
      }, 2000);
    }
  });

  const aboutImageEl = aboutEl.querySelector('.about__image');

  aboutEl.addEventListener('mousemove', (e) => {
    aboutImageEl.style.transform = `translate(${e.clientX / 20}px, ${e.clientY / 20}px)`;
  });

};

export {initIntro};
