const initPublications = () => {
  const publicationsSliderEl = document.querySelector('.publications__slider');

  if (!publicationsSliderEl) {
    return;
  }

  const swiper = new Swiper(publicationsSliderEl, {
    freeMode: {
      enabled: true,
      sticky: false,
      momentumBounce: false,
    },
    slidesPerView: 'auto',
    loop: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
  });

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        swiper.autoplay.start();
      } else {
        swiper.autoplay.stop();
      }
    });
  }, {
    threshold: 0.5,
  });

  observer.observe(publicationsSliderEl);

  // const wrapperEl = publicationsSliderEl.closest('.publications');
  // let isScrolling;
  // let lastScroll = 0;
  // let procent = 0;

  // document.addEventListener('scroll', (e) => {
  //   const st = window.pageYOffset || document.documentElement.scrollTop;


  //   if (lastScroll > st) {
  //     //let procent = (st - wrapperEl.offsetTop - publicationsSliderEl.offsetTop + publicationsSliderEl.offsetHeight) / 25;


  //     // procent += 0.5;

  //     // procent = Math.min(Math.max(procent, 0), 100);

  //     // publicationsSliderEl.style.transform = `translateX(-${procent}%)`;

  //     swiper.setTranslate(swiper.getTranslate() - 10);
  //     swiper.update()

  //     window.clearTimeout(isScrolling);

  //     swiper.autoplay.stop();

  //     isScrolling = setTimeout(() => {
  //       swiper.autoplay.start();
  //     }, 66);
  //   }

  //   lastScroll = st;

  // });
};

export {initPublications};
