const initFieldText = () => {
  const fields = document.querySelectorAll('.field-text');

  if (!fields.length) {
    return;
  }

  fields.forEach((field) => {
    const input = field.querySelector('.field-text__input');

    if (input) {
      input.addEventListener('keyup', (e) => {
        field.classList.toggle('field-text--filled', e.target.value);
      });

      input.addEventListener('phoneInputBlur', (e) => {
        field.classList.toggle('field-text--filled', input.value);
        console.log(input.value)
      });
    }
  });
};

export {initFieldText};
