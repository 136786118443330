const initFormValidate = () => {
  const formEls = document.querySelectorAll('[data-form-validate]');

  if (!formEls.length) {
    return;
  }

  formEls.forEach((formEl) => {
    const pristine = new window.Pristine(formEl, {
      classTo: 'field-text',
      errorClass: 'field-text--error',
      successClass: 'field-text--success',
      errorTextParent: 'field-text',
      errorTextTag: 'div',
      errorTextClass: 'field-text__error',
    }, true);

    const message = formEl.querySelector('.form-message');

    if(!window.pristines){
     window.pristines = [];
    }
    window.pristines.push({
    	'form': formEl,
    	'pristine': pristine
    });

    formEl.addEventListener('submit', (e) => {
      const valid = pristine.validate();

      if (message) {
        e.preventDefault();
        message.classList.remove('form-message--hidden');
      }

      if (!valid) {
        e.preventDefault();
      }
    });
  });
};

export {initFormValidate};
